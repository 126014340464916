body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 16px;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-color: #f5f5f5;
}

main {
    flex-grow: 1;
}

*,
*::before,
*::after {
    box-sizing: border-box;
}

.button, button, input, select {
    font: inherit;
}

.button, button, select, input[type="color"], input[type="checkbox"] {
    cursor: pointer;
}

.button, button {
    padding: 10px;
    transition: background-color 0.2s ease-out;
    background-color: #d7d7d7;
    border: none;
    color: inherit;
    text-decoration: inherit;
}

input[type="color"] {
    transition: background-color 0.2s ease-out;
    background-color: #d7d7d7;
    border: none;
    padding: 5px;
    height: 30px;
}

select {
    padding: 9px;
    transition: background-color 0.2s ease-out;
    background-color: #d7d7d7;
    border: none;
}

select > option {
    background-color: #f5f5f5;
}

.button:hover, button:hover, select:hover, input[type="color"]:hover {
    background-color: #c3c3c3;
}

a.white, a.black {
    transition: color 0.4s;
}

a.white:link {
    color: #f5f5f5;
    text-decoration: none;
}

a.white:visited {
    color: #f5f5f5;
}

a.white:hover {
    color: #cdcdcd;
}

a.white:active {
    color: #9b9b9b;
}

a.black:link {
    color: #000000;
    text-decoration: none;
}

a.black:visited {
    color: #000000;
}

a.black:hover {
    color: #7a7a7a;
}

a.black:active {
    color: #afafaf;
}

input[type="text"], input[type="number"] {
    border-width: 1px;
    border-style: solid;
    border-color: rgb(205, 205, 205);
    padding: 5px;
}

.accordion {
    background-color: #d7d7d7;
    padding: 10px;
    width: 100%;
    text-align: left;
    border: none;
    outline: none;
    transition: background-color 0.4s;
}

.accordion:after {
    content: '\002B';
    font-weight: bold;
    float: right;
    margin-left: 5px;
}

.accordion.active:after {
    content: "\2212";
}

.accordion.accordion--open {
    cursor: auto;
}

.accordion.accordion--open:after {
    content: none;
}

.accordion:hover {
    background-color: #c3c3c3;
}

.panel {
    padding: 20px;
    background-color: #f0f0f0;
    display: none;
    overflow: hidden;
    grid-template-columns: auto 1fr;
    grid-gap: 15px;
    align-items: center;
}

.panel.panel--open {
    display: grid;
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.container > * {
    width: 100%;
    max-width: 1008px;
}

.row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.row--qr-header {
    align-items: center;
}

.row--body {
    align-items: start;
}

.col {
    padding: 20px;
}

.buttons-container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.buttons-container button {
    margin-left: 10px;
}

.buttons-container button:first-of-type {
    margin-left: auto;
}

.space-between-container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
}

.buttons-container button {
    margin-left: 10px;
}

.buttons-container button:first-of-type {
    margin-left: auto;
}

#form-image-file {
    flex-grow: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 150px;
}

.qr-logo {
    flex-grow: 1;
    padding-top: 5px;
    padding-bottom: 5px;
}

.qr-logo__img {
    height: 50px;
}

.qr-description {
    color: #fff;
    min-height: 30vh;
    justify-content: center;
    font-size: 21px;
}

.qr-description__lib-name {
    font-size: 48px;
}

.qr-form {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.qr-header {
    background-color: #333333;
    color: #f5f5f5;
}

.qr-footer {
    background-color: #333333;
    color: #f5f5f5;
}

.qr-code canvas {
    max-width: 300px!important;
    width: 100%;
}

.qr-download-group {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.qr-download-group select {
    border-left: 1px solid  #000;
}

.qr-code-container {
    max-width: 100%;
    position: sticky;
    top: 5px;
}

.options-export-group {
    margin-top: 20px;
}

#form-data {
    width: 100%;
}

.hide {
    /*visibility: hidden;*/
    height: 0;
    width: 0;
}
